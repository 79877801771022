export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0,2,5,6];

export const dictionary = {
		"/": [~8],
		"/(private)/(app)/admin/[page]": [12,[2,3,5]],
		"/(private)/(app)/(inventory)/assets": [~9,[2,3,4]],
		"/(private)/create/community": [23,[2]],
		"/(private)/create/remix/[community]": [~24,[2]],
		"/(private)/(app)/discover/[[sort]]": [~13,[2,3]],
		"/(private)/(app)/dms/[chatId]": [~14,[2,3]],
		"/(private)/(app)/explore": [15,[2,3]],
		"/(private)/(app)/friends": [16,[2,3]],
		"/(private)/(app)/highlights": [~17,[2,3]],
		"/(private)/(app)/highlights/[contentId]": [~18,[2,3]],
		"/(private)/(app)/(inventory)/inventory": [10,[2,3,4]],
		"/(private)/(app)/profile/[[publicId]]": [19,[2,3]],
		"/(private)/(app)/(inventory)/shop": [~11,[2,3,4]],
		"/(private)/(app)/start": [20,[2,3]],
		"/(private)/tests/chat": [25,[2]],
		"/tests/designsystem": [26,[7]],
		"/tests/particles": [27,[7]],
		"/tests/sidebar": [28,[7]],
		"/tests/slider": [29,[7]],
		"/tests/starfield": [30,[7]],
		"/welcome/[name]": [~31],
		"/(private)/[community]/[[channel]]/space": [22,[2,6]],
		"/(private)/[community]/[[channel]]": [21,[2,6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';